@function button-size( $multiplier: 1 ) {
    @return calc( #{$multiplier} * ( #{$button-border-width * 2} + #{$button-padding-y * 2} + #{$button-line-height * 1em} ) );
}
@function button-inner-size( $multiplier: 1 ) {
    @return calc( #{$multiplier} * ( #{$button-padding-y * 2} + #{$button-line-height * 1em} ) );
}


@function toolbar-button-size( $multiplier: 1 ) {
    @return calc( #{$multiplier} * ( #{$toolbar-button-border-width * 2} + #{$toolbar-button-padding-y * 2} + #{$toolbar-button-line-height * 1em} ) );
}
@function toolbar-button-inner-size( $multiplier: 1 ) {
    @return calc( #{$multiplier} * ( #{$toolbar-button-padding-y * 2} + #{$toolbar-button-line-height * 1em} ) );
}
