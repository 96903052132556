$flat-button-hover-opacity: .12 !default;
$flat-button-focused-opacity: .12 !default;
$flat-button-active-opacity: .16 !default;
$flat-button-selected-opacity: .16 !default;
$outline-button-border-width: 2px !default;
$split-button-arrow-border-width: 1px;

@import "../../modules/@progress/kendo-theme-default/scss/button/_layout.scss";

@include exports("button/layout/material") {

    .k-button {
        text-transform: uppercase;

        &::before {
            display: block;
            transition: opacity .4s cubic-bezier(.25, .8, .25, 1);
        }
    }


    // Outline button
    .k-button.k-outline {
        border: $outline-button-border-width solid currentColor;

        &:not(.k-button-icon) {
            padding-top: calc( #{$button-padding-y} - #{$outline-button-border-width} );
            padding-bottom: calc( #{$button-padding-y} - #{$outline-button-border-width} );
            padding-left: calc( #{$button-padding-x} - #{$outline-button-border-width} );
            padding-right: calc( #{$button-padding-x} - #{$outline-button-border-width} );
        }
    }


    // Outline button group
    .k-button-group-outline {
        .k-button ~ .k-button {
            border-left-width: 0;
        }
    }
    .k-rtl .k-button-group-outline {
        .k-button ~ .k-button {
            border-left-width: $outline-button-border-width;
            border-right-width: 0;
        }
    }
}
