$forms-invalid-color: $error !default;

@include exports("forms/layout") {

    .k-nodata {
        min-height: 138px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        font-weight: lighter;
        text-align: center;
        white-space: normal;
    }


    // Searchbar
    .k-searchbar {
        flex: 1;
        display: flex;
        flex-direction: row;
    }


    // Wrappers
    .k-dropdown-wrap {
        @include border-radius( $input-border-radius );
        padding: 0;
        width: 100%;
        border-width: 1px;
        border-style: solid;
        box-sizing: border-box;
        position: relative;
        transition: all .1s ease; // sass-lint:disable-block no-transition-all
        // TODO: improve wrapper overflow
        //overflow: hidden;
        cursor: default;
        outline: 0;

        .k-input {
            padding: $input-padding-y $input-padding-x;
            height: calc( #{$input-padding-y * 2} + #{$input-line-height * 1em} );
            box-sizing: border-box;
            border: 0;
            outline: 0;
            color: inherit;
            background: none;
            font: inherit;
            flex: 0 1 auto;
            display: flex;
            align-items: center;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        // Invalid
        &.k-invalid,
        &.k-invalid:hover,
        &.k-state-invalid,
        &.ng-invalid.ng-touched,
        &.ng-invalid.ng-dirty {
            color: $error;
            border-color: $invalid-border;
        }
    }

    // Other common
    .k-dropdown-wrap {
        display: flex;
        flex-direction: row;

        .k-input {
            width: 100%;

            .k-edge & {
                min-width: 0;
            }
        }

        .k-select {
            @include border-right-radius-only();
            padding: $picker-select-padding-y $picker-select-padding-x;
            border-width: 0 0 0 $picker-select-border-width;
            box-sizing: border-box;
            border-style: solid;
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 0 0 auto;
            text-align: center;
            cursor: pointer;

            .k-icon {}
        }
    }

    // Clear value
    .k-dropdown-wrap {

        .k-clear-value {
            height: ($input-line-height * $input-font-size);
            line-height: ($input-line-height * $input-font-size);
            outline: 0;
            cursor: pointer;
            display: none;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            opacity: .5;
            position: absolute;
            top: $input-padding-y;
            right: $input-icon-offset;


            .k-rtl &,
            [dir="rtl"] & {
                right: auto;
                left: $input-icon-offset;
            }
        }
        .k-clear-value:hover {
            opacity: 1;
        }
    }

    .k-dropdown-wrap {
        &.k-state-focused,
        &.k-state-hover,
        &:hover {
            .k-clear-value {
                display: inline-flex;
            }
        }
    }

    .k-dropdown-wrap {

        .k-clear-value {
            right: $picker-icon-offset;

            .k-rtl &,
            [dir="rtl"] & {
                right: auto;
                left: $picker-icon-offset;
            }
        }
    }

    .k-virtual-content {
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        position: relative;

        > .k-virtual-list > .k-virtual-item {
            position: absolute;
            width: 100%;
            box-sizing: border-box;
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;
            white-space: nowrap;
        }
    }

}




@include exports( "stylehelpers/layout" ) {

    // Style helpers
    .k-textbox.k-space-left {
        padding-left: 1.9em;
    }

    .k-textbox.k-space-right {
        padding-right: 1.9em;
    }

    .k-textbox .k-icon {
        top: 50%;
        margin: -8px 0 0;
        position: absolute;
    }

    .k-space-left .k-icon {
        left: 3px;
    }

    .k-space-right .k-icon {
        right: 3px;
    }

    .k-widget.k-state-invalid,
    .ng-invalid.ng-touched,
    .ng-invalid.ng-dirty {
        &.k-autocomplete,
        &.k-maskedtextbox > .k-textbox {
            color: $forms-invalid-color;
            border-color: $invalid-border;
        }

        > .k-dateinput-wrap,
        > .k-dropdown-wrap,
        > .k-picker-wrap,
        > .k-multiselect-wrap,
        > .k-numeric-wrap {
            color: $forms-invalid-color;
            border-color: $invalid-border;
        }
    }

}

@include exports("forms/integration-with-bootstrap-forms") {
    $twbs-font-size-base: 1rem !default;
    $twbs-input-padding-x: .75rem !default;
    $twbs-input-padding-y: .5rem !default;
    $twbs-input-line-height: 1.25 !default;

    $bootstrap-integration-input-height: ($twbs-input-padding-y * 2) + ($twbs-font-size-base * $twbs-input-line-height) !default;

    $twbs-font-size-base-sm: .875rem !default;
    $twbs-input-padding-x-sm: .5rem !default;
    $twbs-input-padding-y-sm: .25rem !default;
    $twbs-input-line-height-sm: 1.5 !default;

    $bootstrap-integration-input-height-sm: ($twbs-input-padding-y-sm * 2) + ($twbs-font-size-base-sm * $twbs-input-line-height-sm) !default;

    $twbs-font-size-base-lg: 1.25rem !default;
    $twbs-input-padding-x-lg: 1rem !default;
    $twbs-input-padding-y-lg: .5rem !default;
    $twbs-input-line-height-lg: 1.5 !default;

    $bootstrap-integration-input-height-lg: ($twbs-input-padding-y-lg * 2) + ($twbs-font-size-base-lg * $twbs-input-line-height-lg) !default;

    $numerictextbox-icon-offset-sm: 2px !default;

    .k-widget.form-control {
        padding: 0;
    }

    .k-widget.form-control,
    .k-maskedtextbox.form-control .k-textbox,
    .k-textbox.form-control {
        width: 100%;
    }

    .k-autocomplete.form-control .k-input {
        box-sizing: border-box;
    }

    .k-multiselect.form-control {
        height: auto;
    }

    .input-group {
        .k-dateinput-wrap,
        .k-dropdown-wrap,
        .k-multiselect-wrap,
        .k-numeric-wrap,
        .k-picker-wrap,
        .k-maskedtextbox .k-textbox {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;

            .k-select {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }

        .k-widget.form-control,
        .k-maskedtextbox.form-control .k-textbox,
        .k-textbox.form-control {
            width: auto;
        }

        .k-widget.form-control:not(:first-child):not(:last-child) {
            .k-dateinput-wrap,
            .k-dropdown-wrap,
            .k-multiselect-wrap,
            .k-numeric-wrap,
            .k-picker-wrap,
            > .k-textbox {
                border-radius: 0;
            }
        }
    }

    .input-group-prepend + .k-widget.form-control {
        .k-dateinput-wrap,
        .k-dropdown-wrap,
        .k-multiselect-wrap,
        .k-numeric-wrap,
        .k-picker-wrap,
        > .k-textbox {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-top-right-radius: $border-radius;
            border-bottom-right-radius: $border-radius;
        }
    }

    // Bootstrap form-control, form-control-sm, form-control-lg
    .k-widget {
        &.form-control {
            .k-input {
                padding: $twbs-input-padding-y $twbs-input-padding-x;
                height: $bootstrap-integration-input-height;
            }

            &-sm {
                .k-input {
                    padding: $twbs-input-padding-y-sm $twbs-input-padding-x-sm;
                    height: $bootstrap-integration-input-height-sm;
                }

                .k-dateinput-wrap,
                .k-dropdown-wrap,
                .k-picker-wrap {
                    .k-select {
                        padding: $twbs-input-padding-y-sm;
                    }
                }

                .k-select {
                    width: $bootstrap-integration-input-height-sm;
                }
            }

            &-lg {
                .k-input {
                    padding: $twbs-input-padding-y-lg $twbs-input-padding-x-lg;
                    height: $bootstrap-integration-input-height-lg;
                }

                .k-dateinput-wrap,
                .k-dropdown-wrap,
                .k-picker-wrap {
                    .k-select {
                        padding: $twbs-input-padding-y-lg;
                    }
                }

                .k-select {
                    width: $bootstrap-integration-input-height-lg;
                }
            }
        }
    }

    .k-maskedtextbox {
        &.form-control {
            .k-textbox {
                height: calc( #{$bootstrap-integration-input-height} + #{$input-border-width * 2} );
                padding: $twbs-input-padding-y $twbs-input-padding-x;
            }

            &-sm {
                .k-textbox {
                    height: calc( #{$bootstrap-integration-input-height-sm} + #{$input-border-width + 2} );
                    padding: $twbs-input-padding-y-sm $twbs-input-padding-x-sm;
                    line-height: $twbs-input-line-height-sm;
                }
            }

            &-lg {
                .k-textbox {
                    height: calc( #{$bootstrap-integration-input-height-lg} + #{$input-border-width * 2} );
                    padding: $twbs-input-padding-y-lg $twbs-input-padding-x-lg;
                    line-height: $twbs-input-line-height-lg;
                }
            }
        }
    }

    .k-textbox {
        &.form-control {
            height: calc( #{$bootstrap-integration-input-height} + #{$input-border-width * 2} );
            padding: $twbs-input-padding-y $twbs-input-padding-x;

            &-sm {
                height: calc( #{$bootstrap-integration-input-height-sm} + #{$input-border-width * 2} );
                padding: $twbs-input-padding-y-sm $twbs-input-padding-x-sm;
                line-height: $twbs-input-line-height-sm;
            }

            &-lg {
                height: calc( #{$bootstrap-integration-input-height-lg} + #{$input-border-width * 2} );
                padding: $twbs-input-padding-y-lg $twbs-input-padding-x-lg;
                line-height: $twbs-input-line-height-lg;
            }
        }
    }

    .k-dateinput,
    .k-numerictextbox {
        &.form-control {
            &-sm {
                .k-link-increase > .k-icon {
                    bottom: -$numerictextbox-icon-offset-sm;
                }

                .k-link-decrease > .k-icon {
                    top: -$numerictextbox-icon-offset-sm;
                }

                .k-link {
                    height: ($bootstrap-integration-input-height-sm / 2);
                }
            }

            &-lg {
                .k-link {
                    height: $bootstrap-integration-input-height-lg / 2;
                }
            }
        }
    }

    .k-dateinput {
        &.form-control {
            .k-dateinput-wrap {
                .k-select {
                    padding-top: 0;
                    padding-bottom: 0;
                }
            }
        }
    }
}
