@include exports( "common/utils/display" ) {

    // sass-lint:disable-block no-important

    // Display modes
    .k-display-inline           { display: inline !important; }
    .k-display-block            { display: block !important; }
    .k-display-inline-block     { display: inline-block !important; }
    .k-display-flex             { display: flex !important; }
    .k-display-inline-flex      { display: inline-flex !important; }
    .k-display-table            { display: table !important; }
    .k-display-inline-table     { display: inline-table !important; }
    .k-display-none             { display: none !important; }


    // Aliases
    .k-hidden { @extend .k-display-none; }

}
