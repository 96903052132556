$_button-imported: false !default;

@if ( $_button-imported == false ) {

    %appearance_button {
        @include fill(
            $button-text,
            $button-bg,
            $button-border,
            $button-gradient
        );
    }
    %appearance_hovered-button {
        @include fill(
            $button-hovered-text,
            $button-hovered-bg,
            $button-hovered-border,
            $button-hovered-gradient
        );
    }
    %appearance_pressed-button {
        @include fill(
            $button-pressed-text,
            $button-pressed-bg,
            $button-pressed-border,
            $button-pressed-gradient
        );
        @include box-shadow( $button-pressed-shadow );
    }
    %appearance_focused-button {
        @include box-shadow( $button-focused-shadow );
    }

    %appearance_primary-button {
        @include fill(
            $primary-button-text,
            $primary-button-bg,
            $primary-button-border,
            $primary-button-gradient
        );
    }
    %appearance_hovered-primary-button {
        @include fill(
            $primary-button-hovered-text,
            $primary-button-hovered-bg,
            $primary-button-hovered-border,
            $primary-button-hovered-gradient
        );
    }
    %appearance_pressed-primary-button {
        @include fill(
            $primary-button-pressed-text,
            $primary-button-pressed-bg,
            $primary-button-pressed-border,
            $primary-button-pressed-gradient
        );
    }

    %appearance_toolbar {
        @include fill(
            $toolbar-text,
            $toolbar-bg,
            $toolbar-border,
            $toolbar-gradient
        );
    }

    $_button-imported: true;

}


@mixin appearance_button { @extend %appearance_button !optional; }
@mixin appearance_hovered-button { @extend %appearance_hovered-button !optional; }
@mixin appearance_pressed-button { @extend %appearance_pressed-button !optional; }
@mixin appearance_focused-button { @extend %appearance_focused-button !optional; }

@mixin appearance_primary-button { @extend %appearance_primary-button !optional; }
@mixin appearance_hovered-primary-button { @extend %appearance_hovered-primary-button !optional; }
@mixin appearance_pressed-primary-button { @extend %appearance_pressed-primary-button !optional; }

@mixin appearance_toolbar { @extend %appearance_toolbar !optional; }
