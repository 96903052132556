@import "../../modules/@progress/kendo-theme-default/scss/popup/_theme.scss";

@include exports("popup/theme/material") {

    .k-list .k-item,
    .k-list-optionlabel {

        // Focused state
        &:focus,
        &.k-state-focused {
            background: $list-item-hovered-bg;
        }

    }

    .k-list-container {

        .k-button {

            // Hover state
            &:hover,
            &.k-state-hover {
                background: $list-item-hovered-bg;
            }

            // Active state
            &:active,
            &.k-state-active {
                box-shadow: none;
            }

            //Disabled state
            &.k-state-disabled {
                color: $button-disabled-text;
            }

            // Focus state
            &:focus,
            &.k-state-focused {
                background: $list-item-hovered-bg;
            }

        }
    }

    // Tooltip popup container padding
    .k-animation-container-shown.k-tooltip-wrapper {
        .k-popup-transparent {
            padding: 0;
        }
    }

    .k-list-filter {

        & > .k-textbox {
            @include appearance( hovered-node );
        }
    }
}
