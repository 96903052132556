@include exports( "common/loading" ) {

    // Loading indicator
    .k-loading {
        width: 64px;
        height: 64px;
        display: block;

        .animate {
            animation: loading 2s infinite linear;
        }
    }


    // Loading mask
    .k-loading-mask,
    .k-loading-image,
    .k-loading-color {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    .k-loading-mask {
        z-index: 100;

        &.k-opaque {
            .k-loading-color {
                opacity: 1;
            }
        }
    }
    .k-loading-text {
        text-indent: -4000px;
        text-align: center;
        position: absolute;
    }
    .k-loading-image {
        z-index: 2;
    }
    .k-loading-color {
        background-color: $widget-bg;
        opacity: .3;
    }

    // Loading indicator
    .k-i-loading {
        position: relative;
        background-color: transparent;
        box-sizing: border-box;
        color: currentColor;
    }

    .k-i-loading::before,
    .k-i-loading::after,
    .k-loading-image::before,
    .k-loading-image::after {
        position: absolute;
        top: 50%;
        left: 50%;
        display: inline-block;
        content: "";
        box-sizing: inherit;
        border-radius: 50%;
        border-width: .05em;
        border-style: solid;
        border-color: currentColor;
        border-top-color: transparent;
        border-bottom-color: transparent;
        background-color: transparent;
    }

    .k-icon.k-i-loading::before,
    .k-icon.k-i-loading::after {
        content: "";
    }

    .k-i-loading::before,
    .k-loading-image::before {
        margin-top: -.5em;
        margin-left: -.5em;
        width: 1em;
        height: 1em;
        animation: k-loading-animation .7s linear infinite;
    }

    .k-i-loading::after,
    .k-loading-image::after {
        margin-top: -.25em;
        margin-left: -.25em;
        width: .5em;
        height: .5em;
        animation: k-loading-animation reverse 1.4s linear infinite;
    }

    .k-loading-image::before,
    .k-loading-image::after {
        content: "";
        border-width: .015em;
        font-size: 4em;
    }
}


@include exports("common/loading/animations") {

    @keyframes loading {
        0% {
            stroke-dasharray: 0 251;
            stroke-dashoffset: 502;
        }

        50% {
            stroke-dasharray: 250 1;
        }

        100% {
            stroke-dasharray: 0 251;
            stroke-dashoffset: 0;
        }
    }

    @keyframes k-loading-animation  {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

}
