@import "./font/WebComponentsIcons.scss";

@include exports( "common/icons" ) {
    $icon-font-url: map-get( $data-uris, "WebComponentsIcons.ttf" ) !default;

    @font-face {
        font-family: "WebComponentsIcons";
        font-style: normal;
        font-weight: normal;
        src: url(#{$icon-font-url}) format( "truetype" );
    }

    .k-icon {
        width: 1em;
        height: 1em;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-size: $icon-size;
        font-family: "WebComponentsIcons";
        font-style: normal;
        font-variant: normal;
        font-weight: normal;
        line-height: 1;
        speak: none;
        text-transform: none;
        text-decoration: none;
        display: inline-block;
        vertical-align: middle;

        &::before {
            vertical-align: baseline;
        }

        &:hover,
        &:focus {
            text-decoration: none;
        }
    }

    .k-icon-with-modifier {
        position: relative;
        margin: .25em;
    }

    .k-icon-modifier {
        position: absolute;
        font-size: .5em;
        bottom: 0;
        right: 0;
        margin: 0 -.5em -.5em 0;
    }

    .k-i-none::before {
        content: "";
        display: none;
    }

    .k-widget .k-icon {
        &.k-i-expand,
        &.k-i-collapse {
            cursor: pointer;
        }
    }

    .k-icon-xs { font-size: ( $icon-size * .5 ); }
    .k-icon-sm { font-size: ( $icon-size * .75 ); }
    .k-icon-md { font-size: ( $icon-size * 2 ); }
    .k-icon-lg { font-size: ( $icon-size * 3 ); }
    .k-icon-xl { font-size: ( $icon-size * 4 ); }

    // sass-lint:disable single-line-per-selector
    .k-i-arrow-45-up-right::before { content: "\e000"; }
    .k-i-collapse-ne::before { content: "\e000"; }
    .k-i-resize-ne::before { content: "\e000"; }
    .k-i-arrow-45-down-right::before { content: "\e001"; }
    .k-i-collapse-se::before { content: "\e001"; }
    .k-i-resize-se::before { content: "\e001"; }
    .k-i-arrow-45-down-left::before { content: "\e002"; }
    .k-i-collapse-sw::before { content: "\e002"; }
    .k-i-resize-sw::before { content: "\e002"; }
    .k-i-arrow-45-up-left::before { content: "\e003"; }
    .k-i-collapse-nw::before { content: "\e003"; }
    .k-i-resize-new::before { content: "\e003"; }
    .k-i-arrow-60-up::before { content: "\e004"; }
    .k-i-kpi-trend-increase::before { content: "\e004"; }
    .k-i-arrow-60-right::before { content: "\e005"; }
    .k-i-arrow-60-down::before { content: "\e006"; }
    .k-i-kpi-trend-decrease::before { content: "\e006"; }
    .k-i-arrow-60-left::before { content: "\e007"; }
    .k-i-arrow-end-up::before { content: "\e008"; }
    .k-i-arrow-end-right::before { content: "\e009"; }
    .k-i-arrow-end-down::before { content: "\e00a"; }
    .k-i-arrow-end-left::before { content: "\e00b"; }
    .k-i-arrow-double-60-up::before { content: "\e00c"; }
    .k-i-arrow-seek-up::before { content: "\e00c"; }
    .k-i-arrow-double-60-right::before { content: "\e00d"; }
    .k-i-arrow-seek-right::before { content: "\e00d"; }
    .k-i-forward-sm::before { content: "\e00d"; }
    .k-i-arrow-double-60-down::before { content: "\e00e"; }
    .k-i-arrow-seek-down::before { content: "\e00e"; }
    .k-i-arrow-double-60-left::before { content: "\e00f"; }
    .k-i-arrow-seek-left::before { content: "\e00f"; }
    .k-i-rewind-sm::before { content: "\e00f"; }
    .k-i-arrows-kpi::before { content: "\e010"; }
    .k-i-kpi::before { content: "\e010"; }
    .k-i-arrows-no-change::before { content: "\e011"; }
    .k-i-arrow-overflow-down::before { content: "\e012"; }
    .k-i-arrow-chevron-up::before { content: "\e013"; }
    .k-i-arrow-chevron-right::before { content: "\e014"; }
    .k-i-arrow-chevron-down::before { content: "\e015"; }
    .k-i-arrow-chevron-left::before { content: "\e016"; }
    .k-i-arrow-up::before { content: "\e017"; }
    .k-i-arrow-right::before { content: "\e018"; }
    .k-i-arrow-down::before { content: "\e019"; }
    .k-i-arrow-left::before { content: "\e01a"; }
    .k-i-arrow-drill::before { content: "\e01b"; }
    .k-i-arrow-parent::before { content: "\e01c"; }
    .k-i-arrow-root::before { content: "\e01d"; }
    .k-i-arrows-resizing::before { content: "\e01e"; }
    .k-i-arrows-dimensions::before { content: "\e01f"; }
    .k-i-arrows-swap::before { content: "\e020"; }
    .k-i-drag-and-drop::before { content: "\e021"; }
    .k-i-categorize::before { content: "\e022"; }
    .k-i-grid::before { content: "\e023"; }
    .k-i-grid-layout::before { content: "\e024"; }
    .k-i-group::before { content: "\e025"; }
    .k-i-ungroup::before { content: "\e026"; }
    .k-i-handler-drag::before { content: "\e027"; }
    .k-i-layout::before { content: "\e028"; }
    .k-i-layout-1-by-4::before { content: "\e029"; }
    .k-i-layout-2-by-2::before { content: "\e02a"; }
    .k-i-layout-side-by-side::before { content: "\e02b"; }
    .k-i-layout-stacked::before { content: "\e02c"; }
    .k-i-columns::before { content: "\e02d"; }
    .k-i-rows::before { content: "\e02e"; }
    .k-i-reorder::before { content: "\e02f"; }
    .k-i-menu::before { content: "\e030"; }
    .k-i-more-vertical::before { content: "\e031"; }
    .k-i-more-horizontal::before { content: "\e032"; }
    .k-i-undo::before { content: "\e100"; }
    .k-i-redo::before { content: "\e101"; }
    .k-i-reset::before { content: "\e102"; }
    .k-i-reload::before { content: "\e103"; }
    .k-i-refresh::before { content: "\e103"; }
    .k-i-recurrence::before { content: "\e103"; }
    .k-i-non-recurrence::before { content: "\e104"; }
    .k-i-reset-sm::before { content: "\e105"; }
    .k-i-reload-sm::before { content: "\e106"; }
    .k-i-refresh-sm::before { content: "\e106"; }
    .k-i-recurrence-sm::before { content: "\e106"; }
    .k-i-clock::before { content: "\e107"; }
    .k-i-calendar::before { content: "\e108"; }
    .k-i-save::before { content: "\e109"; }
    .k-i-floppy::before { content: "\e109"; }
    .k-i-print::before { content: "\e10a"; }
    .k-i-printer::before { content: "\e10a"; }
    .k-i-edit::before { content: "\e10b"; }
    .k-i-pencil::before { content: "\e10b"; }
    .k-i-delete::before { content: "\e10c"; }
    .k-i-trash::before { content: "\e10c"; }
    .k-i-attachment::before { content: "\e10d"; }
    .k-i-clip::before { content: "\e10d"; }
    .k-i-attachment-45::before { content: "\e10e"; }
    .k-i-clip-45::before { content: "\e10e"; }
    .k-i-link-horizontal::before { content: "\e10f"; }
    .k-i-hyperlink::before { content: "\e10f"; }
    .k-i-unlink-horizontal::before { content: "\e110"; }
    .k-i-hyperlink-remove::before { content: "\e110"; }
    .k-i-link-vertical::before { content: "\e111"; }
    .k-i-unlink-vertical::before { content: "\e112"; }
    .k-i-lock::before { content: "\e113"; }
    .k-i-unlock::before { content: "\e114"; }
    .k-i-cancel::before { content: "\e115"; }
    .k-i-cancel-outline::before { content: "\e116"; }
    .k-i-cancel-circle::before { content: "\e117"; }
    .k-i-check::before { content: "\e118"; }
    .k-i-checkmark::before { content: "\e118"; }
    .k-i-check-outline::before { content: "\e119"; }
    .k-i-checkmark-outline::before { content: "\e119"; }
    .k-i-success::before { content: "\e119"; }
    .k-i-check-circle::before { content: "\e11a"; }
    .k-i-checkmark-circle::before { content: "\e11a"; }
    .k-i-close::before { content: "\e11b"; }
    .k-i-x::before { content: "\e11b"; }
    .k-i-close-outline::before { content: "\e11c"; }
    .k-i-x-outline::before { content: "\e11c"; }
    .k-i-error::before { content: "\e11c"; }
    .k-i-close-circle::before { content: "\e11d"; }
    .k-i-x-circle::before { content: "\e11d"; }
    .k-i-plus::before { content: "\e11e"; }
    .k-i-plus-outline::before { content: "\e11f"; }
    .k-i-plus-circle::before { content: "\e120"; }
    .k-i-minus::before { content: "\e121"; }
    .k-i-kpi-trend-equal::before { content: "\e121"; }
    .k-i-minus-outline::before { content: "\e122"; }
    .k-i-minus-circle::before { content: "\e123"; }
    .k-i-sort-asc::before { content: "\e124"; }
    .k-i-sort-desc::before { content: "\e125"; }
    .k-i-unsort::before { content: "\e126"; }
    .k-i-sort-clear::before { content: "\e126"; }
    .k-i-sort-asc-sm::before { content: "\e127"; }
    .k-i-sort-desc-sm::before { content: "\e128"; }
    .k-i-filter::before { content: "\e129"; }
    .k-i-filter-clear::before { content: "\e12a"; }
    .k-i-filter-sm::before { content: "\e12b"; }
    .k-i-filter-sort-asc-sm::before { content: "\e12c"; }
    .k-i-filter-sort-desc-sm::before { content: "\e12d"; }
    .k-i-filter-add-expression::before { content: "\e12e"; }
    .k-i-filter-add-group::before { content: "\e12f"; }
    .k-i-login::before { content: "\e130"; }
    .k-i-logout::before { content: "\e131"; }
    .k-i-download::before { content: "\e132"; }
    .k-i-upload::before { content: "\e133"; }
    .k-i-hyperlink-open::before { content: "\e134"; }
    .k-i-hyperlink-open-sm::before { content: "\e135"; }
    .k-i-launch::before { content: "\e136"; }
    .k-i-window::before { content: "\e137"; }
    .k-i-window-maximize::before { content: "\e137"; }
    .k-i-windows::before { content: "\e138"; }
    .k-i-window-restore::before { content: "\e138"; }
    .k-i-tiles::before { content: "\e138"; }
    .k-i-window-minimize::before { content: "\e139"; }
    .k-i-gear::before { content: "\e13a"; }
    .k-i-cog::before { content: "\e13a"; }
    .k-i-custom::before { content: "\e13a"; }
    .k-i-gears::before { content: "\e13b"; }
    .k-i-cogs::before { content: "\e13b"; }
    .k-i-wrench::before { content: "\e13c"; }
    .k-i-settings::before { content: "\e13c"; }
    .k-i-preview::before { content: "\e13d"; }
    .k-i-eye::before { content: "\e13d"; }
    .k-i-zoom::before { content: "\e13e"; }
    .k-i-search::before { content: "\e13e"; }
    .k-i-zoom-in::before { content: "\e13f"; }
    .k-i-zoom-out::before { content: "\e140"; }
    .k-i-pan::before { content: "\e141"; }
    .k-i-move::before { content: "\e141"; }
    .k-i-calculator::before { content: "\e142"; }
    .k-i-cart::before { content: "\e143"; }
    .k-i-shopping-cart::before { content: "\e143"; }
    .k-i-connector::before { content: "\e144"; }
    .k-i-plus-sm::before { content: "\e145"; }
    .k-i-minus-sm::before { content: "\e146"; }
    .k-i-kpi-status-deny::before { content: "\e147"; }
    .k-i-kpi-status-hold::before { content: "\e148"; }
    .k-i-kpi-status-open::before { content: "\e149"; }
    .k-i-play::before { content: "\e200"; }
    .k-i-pause::before { content: "\e201"; }
    .k-i-stop::before { content: "\e202"; }
    .k-i-rewind::before { content: "\e203"; }
    .k-i-forward::before { content: "\e204"; }
    .k-i-volume-down::before { content: "\e205"; }
    .k-i-volume-up::before { content: "\e206"; }
    .k-i-volume-off::before { content: "\e207"; }
    .k-i-hd::before { content: "\e208"; }
    .k-i-subtitles::before { content: "\e209"; }
    .k-i-playlist::before { content: "\e20a"; }
    .k-i-audio::before { content: "\e20b"; }
    .k-i-play-sm::before { content: "\e20c"; }
    .k-i-pause-sm::before { content: "\e20d"; }
    .k-i-stop-sm::before { content: "\e20e"; }
    .k-i-heart-outline::before { content: "\e300"; }
    .k-i-fav-outline::before { content: "\e300"; }
    .k-i-favorite-outline::before { content: "\e300"; }
    .k-i-heart::before { content: "\e301"; }
    .k-i-fav::before { content: "\e301"; }
    .k-i-favorite::before { content: "\e301"; }
    .k-i-star-outline::before { content: "\e302"; }
    .k-i-bookmark-outline::before { content: "\e302"; }
    .k-i-star::before { content: "\e303"; }
    .k-i-bookmark::before { content: "\e303"; }
    .k-i-checkbox::before { content: "\e304"; }
    .k-i-shape-rect::before { content: "\e304"; }
    .k-i-checkbox-checked::before { content: "\e305"; }
    .k-i-tri-state-indeterminate::before { content: "\e306"; }
    .k-i-tri-state-null::before { content: "\e307"; }
    .k-i-circle::before { content: "\e308"; }
    .k-i-radiobutton::before { content: "\e309"; }
    .k-i-shape-circle::before { content: "\e309"; }
    .k-i-radiobutton-checked::before { content: "\e30a"; }
    .k-i-notification::before { content: "\e400"; }
    .k-i-bell::before { content: "\e400"; }
    .k-i-information::before { content: "\e401"; }
    .k-i-info::before { content: "\e401"; }
    .k-i-question::before { content: "\e402"; }
    .k-i-help::before { content: "\e402"; }
    .k-i-warning::before { content: "\e403"; }
    .k-i-exception::before { content: "\e403"; }
    .k-i-photo-camera::before { content: "\e500"; }
    .k-i-image::before { content: "\e501"; }
    .k-i-photo::before { content: "\e501"; }
    .k-i-image-export::before { content: "\e502"; }
    .k-i-photo-export::before { content: "\e502"; }
    .k-i-zoom-actual-size::before { content: "\e503"; }
    .k-i-zoom-best-fit::before { content: "\e504"; }
    .k-i-image-resize::before { content: "\e505"; }
    .k-i-crop::before { content: "\e506"; }
    .k-i-mirror::before { content: "\e507"; }
    .k-i-flip-horizontal::before { content: "\e508"; }
    .k-i-flip-vertical::before { content: "\e509"; }
    .k-i-rotate::before { content: "\e50a"; }
    .k-i-rotate-right::before { content: "\e50b"; }
    .k-i-rotate-left::before { content: "\e50c"; }
    .k-i-brush::before { content: "\e50d"; }
    .k-i-palette::before { content: "\e50e"; }
    .k-i-paint::before { content: "\e50f"; }
    .k-i-droplet::before { content: "\e50f"; }
    .k-i-background::before { content: "\e50f"; }
    .k-i-line::before { content: "\e510"; }
    .k-i-shape-line::before { content: "\e510"; }
    .k-i-brightness-contrast::before { content: "\e511"; }
    .k-i-saturation::before { content: "\e512"; }
    .k-i-invert-colors::before { content: "\e513"; }
    .k-i-transperancy::before { content: "\e514"; }
    .k-i-opacity::before { content: "\e514"; }
    .k-i-greyscale::before { content: "\e515"; }
    .k-i-blur::before { content: "\e516"; }
    .k-i-sharpen::before { content: "\e517"; }
    .k-i-shape::before { content: "\e518"; }
    .k-i-round-corners::before { content: "\e519"; }
    .k-i-front-element::before { content: "\e51a"; }
    .k-i-back-element::before { content: "\e51b"; }
    .k-i-forward-element::before { content: "\e51c"; }
    .k-i-backward-element::before { content: "\e51d"; }
    .k-i-align-left-element::before { content: "\e51e"; }
    .k-i-align-center-element::before { content: "\e51f"; }
    .k-i-align-right-element::before { content: "\e520"; }
    .k-i-align-top-element::before { content: "\e521"; }
    .k-i-align-middle-element::before { content: "\e522"; }
    .k-i-align-bottom-element::before { content: "\e523"; }
    .k-i-thumbnails-up::before { content: "\e524"; }
    .k-i-thumbnails-right::before { content: "\e525"; }
    .k-i-thumbnails-down::before { content: "\e526"; }
    .k-i-thumbnails-left::before { content: "\e527"; }
    .k-i-full-screen::before { content: "\e528"; }
    .k-i-fullscreen::before { content: "\e528"; }
    .k-i-full-screen-exit::before { content: "\e529"; }
    .k-i-fullscreen-exit::before { content: "\e529"; }
    .k-i-reset-color::before { content: "\e52a"; }
    .k-i-paint-remove::before { content: "\e52a"; }
    .k-i-background-remove::before { content: "\e52a"; }
    .k-i-page-properties::before { content: "\e600"; }
    .k-i-bold::before { content: "\e601"; }
    .k-i-italic::before { content: "\e602"; }
    .k-i-underline::before { content: "\e603"; }
    .k-i-font-family::before { content: "\e604"; }
    .k-i-foreground-color::before { content: "\e605"; }
    .k-i-convert-lowercase::before { content: "\e606"; }
    .k-i-convert-uppercase::before { content: "\e607"; }
    .k-i-strikethrough::before { content: "\e608"; }
    .k-i-sub-script::before { content: "\e609"; }
    .k-i-sup-script::before { content: "\e60a"; }
    .k-i-div::before { content: "\e60b"; }
    .k-i-all::before { content: "\e60c"; }
    .k-i-h1::before { content: "\e60d"; }
    .k-i-h2::before { content: "\e60e"; }
    .k-i-h3::before { content: "\e60f"; }
    .k-i-h4::before { content: "\e610"; }
    .k-i-h5::before { content: "\e611"; }
    .k-i-h6::before { content: "\e612"; }
    .k-i-list-ordered::before { content: "\e613"; }
    .k-i-list-numbered::before { content: "\e613"; }
    .k-i-list-unordered::before { content: "\e614"; }
    .k-i-list-bulleted::before { content: "\e614"; }
    .k-i-indent-increase::before { content: "\e615"; }
    .k-i-indent::before { content: "\e615"; }
    .k-i-indent-decrease::before { content: "\e616"; }
    .k-i-outdent::before { content: "\e616"; }
    .k-i-insert-up::before { content: "\e617"; }
    .k-i-insert-top::before { content: "\e617"; }
    .k-i-insert-middle::before { content: "\e618"; }
    .k-i-insert-down::before { content: "\e619"; }
    .k-i-insert-bottom::before { content: "\e619"; }
    .k-i-align-top::before { content: "\e61a"; }
    .k-i-align-middle::before { content: "\e61b"; }
    .k-i-align-bottom::before { content: "\e61c"; }
    .k-i-align-left::before { content: "\e61d"; }
    .k-i-align-center::before { content: "\e61e"; }
    .k-i-align-right::before { content: "\e61f"; }
    .k-i-align-justify::before { content: "\e620"; }
    .k-i-align-remove::before { content: "\e621"; }
    .k-i-text-wrap::before { content: "\e622"; }
    .k-i-rule-horizontal::before { content: "\e623"; }
    .k-i-table-align-top-left::before { content: "\e624"; }
    .k-i-table-align-top-center::before { content: "\e625"; }
    .k-i-table-align-top-right::before { content: "\e626"; }
    .k-i-table-align-middle-left::before { content: "\e627"; }
    .k-i-table-align-middle-center::before { content: "\e628"; }
    .k-i-table-align-middle-right::before { content: "\e629"; }
    .k-i-table-align-bottom-left::before { content: "\e62a"; }
    .k-i-table-align-bottom-center::before { content: "\e62b"; }
    .k-i-table-align-bottom-right::before { content: "\e62c"; }
    .k-i-table-align-remove::before { content: "\e62d"; }
    .k-i-borders-all::before { content: "\e62e"; }
    .k-i-borders-outside::before { content: "\e62f"; }
    .k-i-borders-inside::before { content: "\e630"; }
    .k-i-borders-inside-horizontal::before { content: "\e631"; }
    .k-i-borders-inside-vertical::before { content: "\e632"; }
    .k-i-border-top::before { content: "\e633"; }
    .k-i-border-bottom::before { content: "\e634"; }
    .k-i-border-left::before { content: "\e635"; }
    .k-i-border-right::before { content: "\e636"; }
    .k-i-border-no::before { content: "\e637"; }
    .k-i-borders-show-hide::before { content: "\e638"; }
    .k-i-form::before { content: "\e639"; }
    .k-i-border::before { content: "\e639"; }
    .k-i-form-element::before { content: "\e63a"; }
    .k-i-code-snippet::before { content: "\e63b"; }
    .k-i-select-all::before { content: "\e63c"; }
    .k-i-button::before { content: "\e63d"; }
    .k-i-select-box::before { content: "\e63e"; }
    .k-i-calendar-date::before { content: "\e63f"; }
    .k-i-group-box::before { content: "\e640"; }
    .k-i-textarea::before { content: "\e641"; }
    .k-i-textbox::before { content: "\e642"; }
    .k-i-textbox-hidden::before { content: "\e643"; }
    .k-i-password::before { content: "\e644"; }
    .k-i-paragraph-add::before { content: "\e645"; }
    .k-i-edit-tools::before { content: "\e646"; }
    .k-i-template-manager::before { content: "\e647"; }
    .k-i-change-manually::before { content: "\e648"; }
    .k-i-track-changes::before { content: "\e649"; }
    .k-i-track-changes-enable::before { content: "\e64a"; }
    .k-i-track-changes-accept::before { content: "\e64b"; }
    .k-i-track-changes-accept-all::before { content: "\e64c"; }
    .k-i-track-changes-reject::before { content: "\e64d"; }
    .k-i-track-changes-reject-all::before { content: "\e64e"; }
    .k-i-document-manager::before { content: "\e64f"; }
    .k-i-custom-icon::before { content: "\e650"; }
    .k-i-dictionary-add::before { content: "\e651"; }
    .k-i-image-light-dialog::before { content: "\e652"; }
    .k-i-image-insert::before { content: "\e652"; }
    .k-i-image-edit::before { content: "\e653"; }
    .k-i-image-map-editor::before { content: "\e654"; }
    .k-i-comment::before { content: "\e655"; }
    .k-i-comment-remove::before { content: "\e656"; }
    .k-i-comments-remove-all::before { content: "\e657"; }
    .k-i-silverlight::before { content: "\e658"; }
    .k-i-media-manager::before { content: "\e659"; }
    .k-i-video-external::before { content: "\e65a"; }
    .k-i-flash-manager::before { content: "\e65b"; }
    .k-i-find-and-replace::before { content: "\e65c"; }
    .k-i-find::before { content: "\e65c"; }
    .k-i-copy::before { content: "\e65d"; }
    .k-i-files::before { content: "\e65d"; }
    .k-i-cut::before { content: "\e65e"; }
    .k-i-paste::before { content: "\e65f"; }
    .k-i-paste-as-html::before { content: "\e660"; }
    .k-i-paste-from-word::before { content: "\e661"; }
    .k-i-paste-from-word-strip-file::before { content: "\e662"; }
    .k-i-paste-html::before { content: "\e663"; }
    .k-i-paste-markdown::before { content: "\e664"; }
    .k-i-paste-plain-text::before { content: "\e665"; }
    .k-i-apply-format::before { content: "\e666"; }
    .k-i-clear-css::before { content: "\e667"; }
    .k-i-copy-format::before { content: "\e668"; }
    .k-i-strip-all-formating::before { content: "\e669"; }
    .k-i-strip-css-format::before { content: "\e66a"; }
    .k-i-strip-font-elements::before { content: "\e66b"; }
    .k-i-strip-span-elements::before { content: "\e66c"; }
    .k-i-strip-word-formatting::before { content: "\e66d"; }
    .k-i-format-code-block::before { content: "\e66e"; }
    .k-i-style-builder::before { content: "\e66f"; }
    .k-i-module-manager::before { content: "\e670"; }
    .k-i-hyperlink-light-dialog::before { content: "\e671"; }
    .k-i-hyperlink-insert::before { content: "\e671"; }
    .k-i-hyperlink-globe::before { content: "\e672"; }
    .k-i-hyperlink-globe-remove::before { content: "\e673"; }
    .k-i-hyperlink-email::before { content: "\e674"; }
    .k-i-anchor::before { content: "\e675"; }
    .k-i-table-light-dialog::before { content: "\e676"; }
    .k-i-table-insert::before { content: "\e676"; }
    .k-i-table::before { content: "\e677"; }
    .k-i-table-properties::before { content: "\e678"; }
    .k-i-table-wizard::before { content: "\e678"; }
    .k-i-table-cell::before { content: "\e679"; }
    .k-i-table-cell-properties::before { content: "\e67a"; }
    .k-i-table-column-insert-left::before { content: "\e67b"; }
    .k-i-table-column-insert-right::before { content: "\e67c"; }
    .k-i-table-row-insert-above::before { content: "\e67d"; }
    .k-i-table-row-insert-below::before { content: "\e67e"; }
    .k-i-table-column-delete::before { content: "\e67f"; }
    .k-i-table-row-delete::before { content: "\e680"; }
    .k-i-table-cell-delete::before { content: "\e681"; }
    .k-i-table-delete::before { content: "\e682"; }
    .k-i-cells-merge::before { content: "\e683"; }
    .k-i-cells-merge-horizontally::before { content: "\e684"; }
    .k-i-cells-merge-vertically::before { content: "\e685"; }
    .k-i-cell-split-horizontally::before { content: "\e686"; }
    .k-i-cell-split-vertically::before { content: "\e687"; }
    .k-i-table-unmerge::before { content: "\e688"; }
    .k-i-pane-freeze::before { content: "\e689"; }
    .k-i-row-freeze::before { content: "\e68a"; }
    .k-i-column-freeze::before { content: "\e68b"; }
    .k-i-toolbar-float::before { content: "\e68c"; }
    .k-i-spell-checker::before { content: "\e68d"; }
    .k-i-validation-xhtml::before { content: "\e68e"; }
    .k-i-validation-data::before { content: "\e68f"; }
    .k-i-toggle-full-screen-mode::before { content: "\e690"; }
    .k-i-formula-fx::before { content: "\e691"; }
    .k-i-sum::before { content: "\e692"; }
    .k-i-symbol::before { content: "\e693"; }
    .k-i-dollar::before { content: "\e694"; }
    .k-i-currency::before { content: "\e694"; }
    .k-i-percent::before { content: "\e695"; }
    .k-i-custom-format::before { content: "\e696"; }
    .k-i-decimal-increase::before { content: "\e697"; }
    .k-i-decimal-decrease::before { content: "\e698"; }
    .k-i-font-size::before { content: "\e699"; }
    .k-i-image-absolute-position::before { content: "\e69a"; }
    .k-i-globe-outline::before { content: "\e700"; }
    .k-i-globe::before { content: "\e701"; }
    .k-i-marker-pin::before { content: "\e702"; }
    .k-i-marker-pin-target::before { content: "\e703"; }
    .k-i-pin::before { content: "\e704"; }
    .k-i-unpin::before { content: "\e705"; }
    .k-i-share::before { content: "\e800"; }
    .k-i-user::before { content: "\e801"; }
    .k-i-inbox::before { content: "\e802"; }
    .k-i-blogger::before { content: "\e803"; }
    .k-i-blogger-box::before { content: "\e804"; }
    .k-i-delicious::before { content: "\e805"; }
    .k-i-delicious-box::before { content: "\e806"; }
    .k-i-digg::before { content: "\e807"; }
    .k-i-digg-box::before { content: "\e808"; }
    .k-i-email::before { content: "\e809"; }
    .k-i-envelop::before { content: "\e809"; }
    .k-i-letter::before { content: "\e809"; }
    .k-i-email-box::before { content: "\e80a"; }
    .k-i-envelop-box::before { content: "\e80a"; }
    .k-i-letter-box::before { content: "\e80a"; }
    .k-i-facebook::before { content: "\e80b"; }
    .k-i-facebook-box::before { content: "\e80c"; }
    .k-i-google::before { content: "\e80d"; }
    .k-i-google-box::before { content: "\e80e"; }
    .k-i-google-plus::before { content: "\e80f"; }
    .k-i-google-plus-box::before { content: "\e810"; }
    .k-i-linkedin::before { content: "\e811"; }
    .k-i-linkedin-box::before { content: "\e812"; }
    .k-i-myspace::before { content: "\e813"; }
    .k-i-myspace-box::before { content: "\e814"; }
    .k-i-pinterest::before { content: "\e815"; }
    .k-i-pinterest-box::before { content: "\e816"; }
    .k-i-reddit::before { content: "\e817"; }
    .k-i-reddit-box::before { content: "\e818"; }
    .k-i-stumble-upon::before { content: "\e819"; }
    .k-i-stumble-upon-box::before { content: "\e81a"; }
    .k-i-tell-a-friend::before { content: "\e81b"; }
    .k-i-tell-a-friend-box::before { content: "\e81c"; }
    .k-i-tumblr::before { content: "\e81d"; }
    .k-i-tumblr-box::before { content: "\e81e"; }
    .k-i-twitter::before { content: "\e81f"; }
    .k-i-twitter-box::before { content: "\e820"; }
    .k-i-yammer::before { content: "\e821"; }
    .k-i-yammer-box::before { content: "\e822"; }
    .k-i-behance::before { content: "\e823"; }
    .k-i-behance-box::before { content: "\e824"; }
    .k-i-dribbble::before { content: "\e825"; }
    .k-i-dribbble-box::before { content: "\e826"; }
    .k-i-rss::before { content: "\e827"; }
    .k-i-rss-box::before { content: "\e828"; }
    .k-i-vimeo::before { content: "\e829"; }
    .k-i-vimeo-box::before { content: "\e82a"; }
    .k-i-youtube::before { content: "\e82b"; }
    .k-i-youtube-box::before { content: "\e82c"; }
    .k-i-folder::before { content: "\e900"; }
    .k-i-folder-open::before { content: "\e901"; }
    .k-i-folder-add::before { content: "\e902"; }
    .k-i-folder-up::before { content: "\e903"; }
    .k-i-folder-more::before { content: "\e904"; }
    .k-i-fields-more::before { content: "\e904"; }
    .k-i-aggregate-fields::before { content: "\e905"; }
    .k-i-file::before { content: "\e906"; }
    .k-i-file-vertical::before { content: "\e906"; }
    .k-i-file-add::before { content: "\e907"; }
    .k-i-file-txt::before { content: "\e908"; }
    .k-i-txt::before { content: "\e908"; }
    .k-i-file-csv::before { content: "\e909"; }
    .k-i-csv::before { content: "\e909"; }
    .k-i-file-excel::before { content: "\e90a"; }
    .k-i-file-xls::before { content: "\e90a"; }
    .k-i-excel::before { content: "\e90a"; }
    .k-i-xls::before { content: "\e90a"; }
    .k-i-file-word::before { content: "\e90b"; }
    .k-i-file-doc::before { content: "\e90b"; }
    .k-i-word::before { content: "\e90b"; }
    .k-i-doc::before { content: "\e90b"; }
    .k-i-file-mdb::before { content: "\e90c"; }
    .k-i-mdb::before { content: "\e90c"; }
    .k-i-file-ppt::before { content: "\e90d"; }
    .k-i-ppt::before { content: "\e90d"; }
    .k-i-file-pdf::before { content: "\e90e"; }
    .k-i-pdf::before { content: "\e90e"; }
    .k-i-file-psd::before { content: "\e90f"; }
    .k-i-psd::before { content: "\e90f"; }
    .k-i-file-flash::before { content: "\e910"; }
    .k-i-flash::before { content: "\e910"; }
    .k-i-file-config::before { content: "\e911"; }
    .k-i-config::before { content: "\e911"; }
    .k-i-file-ascx::before { content: "\e912"; }
    .k-i-ascx::before { content: "\e912"; }
    .k-i-file-bac::before { content: "\e913"; }
    .k-i-bac::before { content: "\e913"; }
    .k-i-file-zip::before { content: "\e914"; }
    .k-i-zip::before { content: "\e914"; }
    .k-i-film::before { content: "\e915"; }
    .k-i-css3::before { content: "\e916"; }
    .k-i-html5::before { content: "\e917"; }
    .k-i-html::before { content: "\e918"; }
    .k-i-source-code::before { content: "\e918"; }
    .k-i-view-source::before { content: "\e918"; }
    .k-i-css::before { content: "\e919"; }
    .k-i-js::before { content: "\e91a"; }
    .k-i-exe::before { content: "\e91b"; }
    .k-i-csproj::before { content: "\e91c"; }
    .k-i-vbproj::before { content: "\e91d"; }
    .k-i-cs::before { content: "\e91e"; }
    .k-i-vb::before { content: "\e91f"; }
    .k-i-sln::before { content: "\e920"; }
    .k-i-cloud::before { content: "\e921"; }
    .k-i-file-horizontal::before { content: "\e922"; }
    .k-i-hand::before { content: "\e158"; }
    .k-i-cursor::before { content: "\e159"; }
    .k-i-home::before { content: "\e034"; }
    .k-i-file-video::before { content: "\e93b"; }
    .k-i-file-audio::before { content: "\e93c"; }
    .k-i-file-image::before { content: "\e93d"; }
    .k-i-file-presentation::before { content: "\e93e"; }
    .k-i-file-data::before { content: "\e93f"; }
    .k-i-file-disc-image::before { content: "\e940"; }
    .k-i-file-programming::before { content: "\e941"; }


    // Aliases
    .k-i-page-portrait { @extend .k-i-file-vertical; }
    .k-i-page-landscape { @extend .k-i-file-horizontal; }

    // backwards compatibility
    .k-i-arrow-s::before { @extend .k-i-arrow-60-down; }
    .k-i-arrow-e::before { @extend .k-i-arrow-60-right; }
    .k-i-arrow-n::before { @extend .k-i-arrow-60-up; }
    .k-i-arrow-w::before { @extend .k-i-arrow-60-left; }
    .k-i-seek-n::before { @extend .k-i-arrow-seek-up; }
    .k-i-seek-e::before { @extend .k-i-arrow-end-right; }
    .k-i-seek-s::before { @extend .k-i-arrow-seek-down; }
    .k-i-seek-w::before { @extend .k-i-arrow-end-left; }
    .k-i-sarrow-n::before { @extend .k-i-arrow-60-up; }
    .k-i-sarrow-e::before { @extend .k-i-arrow-60-right; }
    .k-i-sarrow-s::before { @extend .k-i-arrow-60-down; }
    .k-i-sarrow-w::before { @extend .k-i-arrow-60-left; }
    .k-i-collapse::before { @extend .k-i-arrow-60-down; }
    .k-i-expand::before { @extend .k-i-arrow-60-right; }
    .k-i-expand-n::before { @extend .k-i-arrow-60-up; }
    .k-i-expand-e::before { @extend .k-i-arrow-60-right; }
    .k-i-expand-s::before { @extend .k-i-arrow-60-down; }
    .k-i-expand-w::before { @extend .k-i-arrow-60-left; }
    .k-i-resize-nw::before { @extend .k-i-arrow-45-up-left; }
    .k-i-arrowhead-n::before { @extend .k-i-arrow-chevron-up; }
    .k-i-arrowhead-e::before { @extend .k-i-arrow-chevron-right; }
    .k-i-arrowhead-s::before { @extend .k-i-arrow-chevron-down; }
    .k-i-arrowhead-w::before { @extend .k-i-arrow-chevron-left; }
    .k-i-deny::before { @extend .k-i-cancel-outline; }
    .k-plus::before { @extend .k-i-plus; }
    .k-i-add::before { @extend .k-i-plus; }
    .k-i-splus::before { @extend .k-i-plus-sm; }
    .k-minus::before { @extend .k-i-minus; }
    .k-i-sminus::before { @extend .k-i-minus-sm; }
    .k-i-refresh-clear::before { @extend .k-i-non-recurrence; }
    .k-i-restore::before { @extend .k-i-window-restore; }
    .k-i-maximize::before { @extend .k-i-window-maximize; }
    .k-i-minimize::before { @extend .k-i-window-minimize; }
    .k-i-insert-image::before { @extend .k-i-image-insert; }
    .k-i-insert-file::before { @extend .k-i-file-add; }
    .k-i-pdfa::before { @extend .k-i-pdf; }
    .k-i-xlsa::before { @extend .k-i-xls; }
    .k-i-hamburger::before { @extend .k-i-menu; }
    .k-i-vbars::before { @extend .k-i-more-vertical; }
    .k-i-hbars::before { @extend .k-i-more-horizontal; }
    .k-i-dimension::before { @extend .k-i-arrows-dimensions; }
    .k-i-undo-large::before { @extend .k-i-undo; }
    .k-i-redo-large::before { @extend .k-i-redo; }
    .k-i-rotate-ccw::before { @extend .k-i-rotate-left; }
    .k-i-rotate-cw::before { @extend .k-i-rotate-right; }
    .k-i-strike-through::before { @extend .k-i-strikethrough; }
    .k-i-text::before { @extend .k-i-foreground-color; }
    .k-i-fx::before { @extend .k-i-formula-fx; }
    .k-i-subscript::before { @extend .k-i-sub-script; }
    .k-i-superscript::before { @extend .k-i-sup-script; }
    .k-i-increase-decimal::before { @extend .k-i-decimal-increase; }
    .k-i-decrease-decimal::before { @extend .k-i-decimal-decrease; }
    .k-i-justify-left::before { @extend .k-i-align-left; }
    .k-i-justify-center::before { @extend .k-i-align-center; }
    .k-i-justify-right::before { @extend .k-i-align-right; }
    .k-i-justify-full::before { @extend .k-i-align-justify; }
    .k-i-justify-clear::before { @extend .k-i-align-remove; }
    .k-i-insert-n::before { @extend .k-i-insert-up; }
    .k-i-insert-m::before { @extend .k-i-insert-middle; }
    .k-i-insert-s::before { @extend .k-i-insert-down; }
    .k-i-insert-unordered-list::before { @extend .k-i-list-unordered; }
    .k-i-insert-ordered-list::before { @extend .k-i-list-ordered; }
    .k-i-clearformat::before { @extend .k-i-clear-css; }
    .k-i-create-table::before { @extend .k-i-table-light-dialog; }
    .k-i-add-column-left::before { @extend .k-i-table-column-insert-left; }
    .k-i-add-column-right::before { @extend .k-i-table-column-insert-right; }
    .k-i-group-delete::before { @extend .k-i-close; }
    .k-i-delete-column::before { @extend .k-i-table-column-delete; }
    .k-i-add-row-above::before { @extend .k-i-table-row-insert-above; }
    .k-i-add-row-below::before { @extend .k-i-table-row-insert-below; }
    .k-i-delete-row::before { @extend .k-i-table-row-delete; }
    .k-i-merge-cells::before { @extend .k-i-cells-merge; }
    .k-i-normal-layout::before { @extend .k-i-table-unmerge; }
    .k-i-page-layout::before { @extend .k-i-layout-2-by-2; }
    .k-i-all-borders::before { @extend .k-i-borders-all; }
    .k-i-inside-borders::before { @extend .k-i-borders-inside; }
    .k-i-inside-horizontal-borders::before { @extend .k-i-borders-inside-horizontal; }
    .k-i-inside-vertical-borders::before { @extend .k-i-borders-inside-vertical; }
    .k-i-outside-borders::before { @extend .k-i-borders-outside; }
    .k-i-top-border::before { @extend .k-i-border-top; }
    .k-i-right-border::before { @extend .k-i-border-right; }
    .k-i-bottom-border::before { @extend .k-i-border-bottom; }
    .k-i-left-border::before { @extend .k-i-border-left; }
    .k-i-no-borders::before { @extend .k-i-border-no; }
    .k-i-merge-horizontally::before { @extend .k-i-cells-merge-horizontally; }
    .k-i-merge-vertically::before { @extend .k-i-cells-merge-vertically; }
    .k-i-freeze-col::before { @extend .k-i-column-freeze; }
    .k-i-freeze-row::before { @extend .k-i-row-freeze; }
    .k-i-freeze-panes::before { @extend .k-i-pane-freeze; }
    .k-i-format-number::before { @extend .k-i-custom-format; }
    .k-i-fullscreen-enter::before { @extend .k-i-full-screen; }
    .k-i-volume-low::before { @extend .k-i-volume-down; }
    .k-i-volume-high::before { @extend .k-i-volume-up; }
    .k-i-volume-mute::before { @extend .k-i-volume-off; }
    .k-i-tick::before { @extend .k-i-check; }
    .k-i-note::before { @extend .k-i-info; }


    // RTL icons
    .k-rtl .k-i-indent-increase,
    .k-rtl .k-i-indent-decrease,
    .k-rtl .k-i-expand,
    .k-rtl .k-i-collapse,
    [dir="rtl"] .k-i-expand,
    [dir="rtl"] .k-i-collapse {
        transform: scaleX(-1);
    }

    .k-sprite {
        display: inline-block;
        width: 16px;
        height: 16px;
        overflow: hidden;
        background-repeat: no-repeat;
        font-size: 0;
        line-height: 0;
        text-align: center;
        -ms-high-contrast-adjust: none;
    }
    .k-image {
        display: inline-block;
    }
    // sass-lint:enable single-line-per-selector
}
