$forms-invalid-color: inherit !default;

@import "../../modules/@progress/kendo-theme-default/scss/common/_forms.scss";

@include exports("common/forms/material") {
    .k-textbox-container,
    .k-floating-label-container {
        @include input-ripple();
    }

    .k-dropdown-wrap {
        border-width: 0 0 $input-border-width;
        border-color: $input-border;
        background-color: transparent;
        position: relative;

        .k-input {
            color: $input-text;
        }

        .k-select {
            border-width: 0;
            font-size: $font-size;
            opacity: $icon-opacity;
        }

        // Hover
        &:hover,
        &.k-state-hover {
            border-color: $input-hovered-border;

            .k-select {
                opacity: $icon-hovered-opacity;
            }
        }

        // Focused
        &.k-state-focused,
        .k-state-focused > & {
            border-color: $input-focused-border;

            .k-select {
                opacity: $icon-focused-opacity;
            }
        }

        // Disabled
        .k-state-disabled > &,
        &.k-state-disabled {
            border-bottom-style: dashed;
        }
    }

    // Expanded
    .k-state-border-up > .k-select .k-i-arrow-60-down,
    .k-state-border-down > .k-select .k-i-arrow-60-down {
        transform: rotate(180deg);
    }
}
