$_widget-imported: false !default;

@if ( $_widget-imported == false ) {

    %appearance_widget {
        @include fill(
            $widget-text,
            $widget-bg,
            $widget-border
        );
    }

    %appearance_panel {
        @include fill(
            $panel-text,
            $panel-bg,
            $panel-border
        );
    }
    %appearance_popup {
        @include fill(
            $popup-text,
            $popup-bg,
            $popup-border
        );
    }

    %appearance_header {
        @include fill(
            $header-text,
            $header-bg,
            $header-border,
            $header-gradient
        );
    }
    %appearance_hovered-header {
        @include fill(
            $header-hovered-text,
            $header-hovered-bg,
            $header-hovered-border,
            $header-hovered-gradient
        );
    }
    %appearance_selected-header {
        @include fill(
            $header-selected-text,
            $header-selected-bg,
            $header-selected-border,
            $header-selected-gradient
        );
    }

    $_widget-imported: true;

}

@mixin appearance_widget { @extend %appearance_widget !optional; }

@mixin appearance_panel { @extend %appearance_panel !optional; }
@mixin appearance_popup { @extend %appearance_popup !optional; }

@mixin appearance_header { @extend %appearance_header !optional; }
@mixin appearance_hovered-header { @extend %appearance_hovered-header !optional; }
@mixin appearance_selected-header { @extend %appearance_selected-header !optional; }
