$button-disabled-bg: rgba(0, 0, 0, .12) !default;
$button-disabled-text: rgba(0, 0, 0, .38) !default;

@include exports("button/theme") {

    $not-button-selector: ":not(.k-flat):not(.k-bare):not(.k-outline):not(.k-overflow-button)";

    // Default button
    .k-button {
        @include appearance( button );
        box-shadow: $button-shadow;
        font-weight: 500;
        transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1);

        &:hover,
        &.k-state-hover {
            box-shadow: $button-hovered-shadow;
        }

        &:active,
        &.k-state-active {
            box-shadow: $button-pressed-shadow;
        }

        &.k-state-disabled {

            &,
            &:hover,
            &:focus,
            &.k-state-focused {
                color: $button-disabled-text;
                opacity: 1;
            }
        }

    }

    .k-button#{$not-button-selector}.k-state-disabled {
        background-color: $button-disabled-bg;
        box-shadow: none !important; // sass-lint:disable-line no-important

        .k-split-button & {
            background: transparent;
        }
    }


    // Primary button
    .k-button.k-primary {
        @include appearance( primary-button );
    }


    // Variant buttons
    .k-button-flat.k-primary,
    .k-button.k-outline.k-primary {

        &,
        &:hover,
        &.k-state-hover {
            color: $primary-button-bg;
        }

        &.k-state-disabled {
            color: $button-disabled-text;
        }
    }

    // Outline buttons
    .k-button.k-outline.k-state-active,
    .k-button.k-outline:active {
        box-shadow: none;
    }

    // Button group
    .k-button-group {
        box-shadow: $button-shadow;

        .k-button {
            box-shadow: none;
        }
    }
    .k-button-group.k-toggled {
        box-shadow: $button-shadow;
    }
    .k-button-group.k-state-disabled {
        box-shadow: none;
        opacity: 1;

        .k-button {
            color: $button-disabled-text;
        }
        .k-button#{$not-button-selector} {
            background-color: $button-disabled-bg;
        }
    }


    // Variant button group
    .k-button-group-flat,
    .k-button-group-bare,
    .k-button-group-outline {
        box-shadow: none;
    }

}
