@include exports("popup/theme") {

    .k-popup {
        @include appearance( popup );
        @include use-background-clip();
    }

    .k-popup > kendo-list > .k-first {
        border-bottom: 1px solid $border-color;
    }

    .k-popup > .k-group-header,
    .k-popup .k-outer-group-header.k-first,
    .k-popup > .k-virtual-wrap > .k-group-header {
        border-bottom-color: $border-color;
        box-shadow: $list-container-shadow;
    }
    .k-popup .k-outer-group-header {
        border-bottom-color: $border-color;
    }
    .k-popup .k-list .k-item > .k-group {
        color: $grouping-header-text;
        background: $grouping-header-bg;
    }
    .k-popup .k-list .k-item > .k-group::before {
        border-color: $grouping-header-bg $grouping-header-bg transparent transparent;
    }
    .k-rtl.k-popup .k-list .k-item > .k-group::before,
    [dir="rtl"] .k-popup .k-list .k-item > .k-group::before {
        border-color: $grouping-header-bg transparent transparent $grouping-header-bg;
    }
    .k-group-header+div > .k-list > .k-item.k-first::before {
        border-color: $grouping-border;
    }
    .k-list > .k-outer-group-header {
        border-color: $grouping-border;
    }
    .k-popup > .k-colorpalette,
    .k-popup > .k-flatcolorpicker {
        background: transparent;

        &:only-child {
            background: #f5f5f5;
        }
    }
}

@include exports( "list/theme" ) {

    .k-list {}

    .k-list .k-item,
    .k-list-optionlabel {
        transition-property: color, background-color, outline-color, box-shadow;
        transition-duration: 200ms;
        transition-timing-function: ease;
    }

    .k-list .k-item:hover,
    .k-list .k-item.k-state-hover,
    .k-list-optionlabel:hover,
    .k-list-optionlabel.k-state-hover {
        @include appearance( hovered-list-item );

        &.k-state-selected {
            color: $list-item-selected-text;
            background-color: shade( $list-item-selected-bg );
        }
    }

    .k-list .k-item:focus,
    .k-list .k-item.k-state-focused,
    .k-list-optionlabel:focus,
    .k-list-optionlabel.k-state-focused {
        @include appearance( focused-list-item );
    }

    .k-list .k-item.k-state-selected,
    .k-list-optionlabel.k-state-selected {
        @include appearance( selected-list-item );
    }

    .k-animation-container {
        @include border-bottom-radius-only();
    }

    .k-animation-container-shown,
    .k-animation-container > .k-popup {
        box-shadow: $popup-shadow;
    }

    .k-animation-container-fixed > .k-popup {
        box-shadow: none;
    }
}




@include exports( "listcontainer/theme" ) {


    .k-list-container {

        // Button normal state
        .k-button {
            box-shadow: none;
        }


        // Button hover state
        .k-button:hover,
        .k-button.k-state-hover {
            @include appearance( hovered-list-item );
            background-image: none;
        }

        // Button pressed state
        .k-button:active,
        .k-button.k-state-active {
            @include appearance( selected-list-item );
            background-image: none;
        }

        // Button disabled state
        .k-button.k-state-disabled {
            background: transparent;
        }


        // Button focus state
        .k-button:focus,
        .k-button.k-state-focused {
            box-shadow: $list-item-focused-shadow;
        }

        .k-nodata {
            color: $list-container-no-data-text;
        }

    }

}
