$_node-imported: false !default;

@if ( $_node-imported == false ) {

    %appearance_node {
        @include fill(
            $node-text,
            $node-bg,
            $node-border,
            $node-gradient
        );
    }
    %appearance_hovered-node {
        @include fill(
            $node-hovered-text,
            $node-hovered-bg,
            $node-hovered-border,
            $node-hovered-gradient
        );
    }
    %appearance_selected-node {
        @include fill(
            $node-selected-text,
            $node-selected-bg,
            $node-selected-border,
            $node-selected-gradient
        );
    }

    %appearance_list-item {
        @include fill(
            $list-item-text,
            $list-item-bg
        );
    }
    %appearance_focused-list-item {
        @include box-shadow( $list-item-focused-shadow );
    }
    %appearance_hovered-list-item {
        @include fill(
            $list-item-hovered-text,
            $list-item-hovered-bg
        );
    }
    %appearance_selected-list-item {
        @include fill(
            $list-item-selected-text,
            $list-item-selected-bg
        );
    }

    $_node-imported: true;

}


@mixin appearance_node { @extend %appearance_node !optional; }
@mixin appearance_hovered-node { @extend %appearance_hovered-node !optional; }
@mixin appearance_selected-node { @extend %appearance_selected-node !optional; }

@mixin appearance_list-item { @extend %appearance_list-item !optional; }
@mixin appearance_hovered-list-item { @extend %appearance_hovered-list-item !optional; }
@mixin appearance_focused-list-item { @extend %appearance_focused-list-item !optional; }
@mixin appearance_selected-list-item { @extend %appearance_selected-list-item !optional; }
